import React from "react"
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from "../components/layout"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Image from "react-bootstrap/Image"

import faExternalLink from "../images/fa-external-link-solid.svg"
import mountains from "../images/mountains.svg"

export default function Resources() {
  return (
    <Layout>
      <GatsbySeo
        title="Resources"
        description="If you are in danger or having a mental health emergency click here for organisations that can provide help immediately"
        canonical="https://yourtherapydoncaster.co.uk/resources"
        openGraph={{
         url: "https://yourtherapydoncaster.co.uk/resources",
         title: "Resources",
         description: "If you are in danger or having a mental health emergency click here for organisations that can provide help immediately",
         site_name: "YourTherapy",
       }}
      />
      <Container fluid="xl" className="my-5">
        <Row xs={1}>
          <Col className="d-flex flex-column justify-content-center align-items-center">
            <p class="uppercase-text font-weight-bold">Emergency</p>
            <p>call 112 or 999</p>
            <p class="uppercase-text font-weight-bold mt-1">Non-emergency</p>
            <p>call 111, Option 2</p>
            <p class="uppercase-text font-weight-bold mt-1">24/7 Helpline:</p>
            <p>call 116 123 (UK and ROI)</p>
            <Button variant="outline-dark" className="bg-white" href="https://www.samaritans.org/how-we-can-help-you/contact-us" rel="noreferrer" class="ml-1 p-1 border border-dark rounded">
            or visit Samaritans.org
                <Image height="10" src={faExternalLink} className="ml-1" alt=""/>
            </Button>
          </Col>
        </Row>
        <Row className="mt-5 text-center">
          <Col xs={12} sm={{span:10,offset:1}} md={{span:6,offset:3}} lg={{span:4,offset:4}}>
          <Button variant="outline-dark" className="bg-white" href="https://www.nhs.uk/using-the-nhs/nhs-services/mental-health-services/where-to-get-urgent-help-for-mental-health/" norel noopen>
                NHS guidance on urgent local help for mental health
                <Image height="10" src={faExternalLink} className="ml-1" alt="" />
          </Button>
          </Col>
        </Row>
      </Container>
      <Container fluid="xl">
        <Row xs={1}>
          <Col md={{span:10}} className="d-flex flex-column align-content-center" style={{marginTop:"-20vh",zIndex:-1}}>
            <Image src={mountains} alt="" fluid/>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
